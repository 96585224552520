import { navigate, PageProps } from "gatsby";
import { useContext, useEffect } from "react";
import { Context } from "../components/Provider";
import { useToken } from "../hooks/useToken";

export default function LogoutPage({ location }: PageProps) {
  const [, setToken] = useToken();
  const {
    updateECodes,
    updateBasket,
    updateCustomer,
    updateApiRetailer,
    updateRetailerLookup,
    updateOrderPageData
  } = useContext(Context);
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setToken();
    updateECodes([]);
    updateBasket([]);
    updateCustomer(null);
    updateApiRetailer(null);
    updateRetailerLookup({});
    updateOrderPageData(null);
    navigate(searchParams.get("redirect") || "/");
  }, []);

  return null;
}
